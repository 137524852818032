import React, {useContext, useEffect, useState} from "react";
import classNames from "classnames";
import { ToastContainer, toast, useToast } from 'react-toastify';
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
} from "reactstrap";
import { drizzleConnect } from '@drizzle/react-plugin';
import ReactTable from "components/ReactTable/ReactTable.js";
import { DrizzleContext, DrizzleProvider } from "@drizzle/react-plugin";
import { createJsxText, OperationCanceledException } from "typescript";
import coinAddressValidator from 'coin-address-validator';
import { newContextComponents } from "@drizzle/react-components";
import { AlbazeaInteractor, translateException } from "../../albazea/interactions";
import { AlbazeaContractStatus } from "../../components/Albazea/ContractStatus";
import { AlbazeaContractTimestamp } from "../../components/Albazea/ContractTimestamp";

import {isContractWithdrawable, isContractTerminable, isContractRenewable, printTime } from "../../albazea/albazeaUtils";
import logo from "assets/img/logo.png"; //logo blanco de Albazea

const { AccountData } = newContextComponents;


const ViewBeneficiaryContracts = (props) => {

  const {drizzle, drizzleState, initialized} =  useContext(DrizzleContext.Context);
  const interactor = new AlbazeaInteractor(drizzle);
  
  const getContractsByBeneficiary =  async function () {
 
    let data = await interactor.getContractsByBeneficiary(props.accounts[0]);
    return data;
}
  
  const [data, setData] = React.useState([]);

  const reload = async () => {    

    let data = await getContractsByBeneficiary();

    let listData = data.map((prop, key) => {
    return {
      id: key,
      contractId: prop["id"],
      creator: prop["creator"],
      beneficiary: prop["beneficiary"],
      flexible: prop["flexible"] ?  "Flexible" : "Fijo",
      terminable: prop["terminable"] ?  "Cancelable" : "No cancelable",
      amount: drizzle.web3.utils.fromWei(prop["amount"]),
      status:  <AlbazeaContractStatus status={prop["status"]}/>,
      validityTime: printTime(prop["validityTime"]),
      lastUpdateTimestamp: <AlbazeaContractTimestamp timestamp={prop["lastUpdateTimestamp"]}/>,
      actions: (
        // we've added some custom button actions
        <div className="actions-right">
          {/* use this button to add a like kind of action */}
          {" "}
          {/* use this button to add a edit kind of action */}
          {isContractWithdrawable(prop["lastUpdateTimestamp"], prop["validityTime"], prop["status"]) && (
          <Button
            onClick={async () => {
              let obj = listData.find((o) => o.id === key);
              try {
                await interactor.executeContract(obj.contractId);
              } catch(err)  {
                return toast.error( translateException(err), { position: toast.POSITION.BOTTOM_RIGHT });                
              }
            }}
            color="warning"
            size="sm"
          >
            
            Procesar
          </Button>)}{" "}
          
        </div>
      ),
    };
  });

    console.log(data);
    
    setData(listData);
  

  }

  useEffect(() => {
    (async () => {
      reload();
    })();
  }, []);
  

  return (
    <>
      <div className="content">
        <Col md={8} className="ml-auto mr-auto">
        <h1 className="text-center"><img src={logo} style={{maxWidth:"180px"}} alt="zea"></img></h1>
          <h2 className="text-center">Mis Consignas</h2>
          <p className="text-center">
            En esta lista podrás ver todos los contratos que han sido creados con tu cuenta como beneficiario.
          </p>
        </Col>
        <Row className="mt-5">
          <Col xs={12} md={12}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Mis contratos</CardTitle>
              </CardHeader>
              <CardBody>
                <ReactTable
                  next="Siguiente"
                  prev="Anterior"
                  data={data}
                  filterable
                  resizable={false}
                  
                  columns={[
                    {
                      Header: "Id de contrato",
                      accessor: "contractId",
                    },
                    {
                      Header: "Cedente",
                      accessor: "creator",
                    },
                    {
                      Header: "Balance Actual",
                      accessor: "amount",
                    },
                    {
                      Header: "Flexible o fijo",
                      accessor: "flexible",
                    },
                    {
                      Header: "Cancelable",
                      accessor: "terminable",
                    },
                    {
                      Header: "Duración en días",
                      accessor: "validityTime",
                    },
                    {
                      Header: "Última fecha de renovación",
                      accessor: "lastUpdateTimestamp",
                    },
                    {
                      Header: "Estado",
                      accessor: "status",
                    },
                    {
                      Header: "Acciones",
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  defaultPageSize={10}
                  showPaginationTop
                  showPaginationBottom={false}
                  className="-striped -highlight"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

//export default ViewBeneficiary;
const mapStateToProps = state => {
  return {
    accounts: state.accounts,
    accountBalances: state.accountBalances,
    drizzleStatus: state.drizzleStatus,
    Albazea: state.contracts.Albazea,
    web3: state.web3
  }
}
const AppContainer = drizzleConnect(ViewBeneficiaryContracts, mapStateToProps);
export default AppContainer;

// sample component
import React from 'react';

class AlbazeaContractTimestamp extends React.Component {
 

    componentDidMount() {  
      
    }

    render() {

        let utcSeconds = this.props.timestamp;
        let d = new Date(0);
        d.setUTCSeconds(utcSeconds);
        let output = d.toLocaleString();

        return (<span>{output}</span>);
    }
}

export {AlbazeaContractTimestamp};


import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import {ContractEventNotifier} from "./middleware/contract-events";
import drizzleOptions from "./albazea/drizzleOptions";

import { DrizzleProvider, DrizzleContext } from "@drizzle/react-plugin";
import { Drizzle, generateStore } from "@drizzle/store";
import { LoadingContainer }from '@drizzle/react-components';

import { toast } from 'react-toastify';

//import AuthLayout from "layouts/Auth/Auth.js";
//import RTLLayout from "layouts/RTL/RTL.js";
import AdminLayout from "layouts/Admin/Admin.js";


import "assets/css/nucleo-icons.css";
import "react-notification-alert/dist/animate.css";
import "assets/scss/black-dashboard-pro-react.scss?v=1.2.0";
import "assets/demo/demo.css";

import logo from "assets/img/logo.png";

// Let's set up here the redux
const appMiddlewares = [ ContractEventNotifier ];
const store = generateStore({drizzleOptions: drizzleOptions, appMiddlewares: appMiddlewares, disableReduxDevTools: false});
const drizzle = new Drizzle(drizzleOptions, store);



// Toast related stuff
toast.configure();

ReactDOM.render(  

  <DrizzleProvider options={drizzleOptions}>
    <DrizzleContext.Provider drizzle={drizzle}>      
        <LoadingContainer 
        loadingComp={
        <div className="container  h-100" > 
          <br/><br/><br/><br/><br/><br/><br/><br/>
              <div className="row justify-content-center align-self-center text-center">
              <div className="col-xs-12">
              <br/>
              <h1><img src={logo} style={{maxWidth:"200px"}} alt="zea"></img></h1>
                <p>Cargando aplicación... Por favor comprueba que tu navegador o MetaMask está activado y has otorgado los permisos apropiados. Cuando lo hayas solucionado, haz clic en el botón.<br/><br/></p>
                <p><button className="btn btn-info btn-lg" onClick={() => window.location.reload()}>Ya he configurado Metamask</button></p>
              </div>
            </div>
          </div>}
        
        errorComp={
          <div className="container  h-100  align-items-center justify-content-center"  style={{height:"600px"}}> 
          <br/><br/><br/><br/><br/><br/><br/><br/>
              <div className="row justify-content-center align-self-center text-center">
              <div className="col-xs-12">
              <br/>
              <h1><img src={logo} style={{maxWidth:"200px"}} alt="zea"></img></h1>
                <p>Cargando aplicación... Por favor comprueba que tu navegador o MetaMask está activado y has otorgado los permisos apropiados. Cuando lo hayas solucionado, haz clic en el botón.<br/><br/></p>
                <p><button className="btn btn-info btn-lg" onClick={() => window.location.reload()}>Ya he configurado Metamask</button></p>
              </div>
            </div>
          </div>
        }>
        <BrowserRouter>
          <Switch>
            <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
            <Redirect from="/" to="/admin/dashboard" />
          </Switch>
        </BrowserRouter>
    </LoadingContainer>
    </DrizzleContext.Provider>
    </DrizzleProvider>
    ,
      
  document.getElementById('root'),
);
// sample component
import React from 'react';

class AlbazeaContractStatus extends React.Component {
 

 componentDidMount() {
   
   
 }

 render() {
   
  if(this.props.status == 0) {
    return (<span>Abierto</span>);
  } else if(this.props.status == 1) { 
    return (<span>Procesado</span>);
  } else if(this.props.status == 2) { 
    return (<span>Cancelado</span>);
  } else {
    return (<span>Desconocido</span>);
  }
 }
}

export  {AlbazeaContractStatus};


import Albazea from "../contracts/contracts/Albazea.json";
import { AlbazeaEvents } from "./global";

const drizzleOptions = {
    contracts: [Albazea],   
    events: {
      Albazea: [
        AlbazeaEvents.ContractCreated,
        AlbazeaEvents.ContractTerminated,
        AlbazeaEvents.ContractProcessed,
        AlbazeaEvents.ContractFaithOfLifeRefreshed,
      ]
    },
    polls: {
      accounts: 1500
  }
  };

  export default drizzleOptions;
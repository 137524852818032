
import React, {useContext, useEffect, useState} from "react";
import { ToastContainer, toast, useToast } from 'react-toastify';
import Select from "react-select";
//import { connect } from 'react-redux';
import { drizzleConnect } from '@drizzle/react-plugin';
import { AlbazeaEvents } from "albazea/global";
import { AlbazeaInteractor, translateException } from "../../albazea/interactions";

import { AvField } from 'availity-reactstrap-validation';

import { useForm, Controller } from "react-hook-form";
import logo from "assets/img/logo.png"; //logo blanco de Albazea



// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  CustomInput,
  InputGroup,
  InputGroupText,
  UncontrolledDropdown,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu
} from "reactstrap";

  
import { DrizzleContext, DrizzleProvider } from "@drizzle/react-plugin";
import { createJsxText, OperationCanceledException } from "typescript";
import coinAddressValidator from 'coin-address-validator';
import { newContextComponents } from "@drizzle/react-components";
import { printEther } from "albazea/albazeaUtils";
const { AccountData, ContractData } = newContextComponents;



const contractTypes = [
  {
    value: "AAA365",
    label: "AAA365",
    isDisabled: false,
    //isDefault: true
  },
  { value: "ZEA128", label: "ZEA128", isDisabled: false,}, //los he puesto en disabled por lo que comentamos de que debian de estarlo
  { value: "QUICK31", label: "QUICK31", isDisabled: false,},
  //{ value: "AAALBERTO-X", label: "AAALBERTO-X", isDisabled: false,},
];


const validateAddress = (field) => {
  return coinAddressValidator.validate(field, 'eth', 'both');

}



const CreateContract = (props) => {

  const {drizzle, drizzleState, initialized} =  useContext(DrizzleContext.Context);
  const [fromAddress, setFromAddress] = React.useState(props.accounts[0]);
  const [fromBalance, setFromBalance] = React.useState(printEther(props.accountBalances[props.accounts[0]]));
  const [contractType, setContractType] = React.useState(contractTypes[0]);
  const [beneficiaryAddress, setbeneficiaryAddress] = React.useState('');
  const [allowRenewals, setAllowRenewals] = React.useState(false);
  const [allowTermination, setAllowTermination] = React.useState(false);
  const [contractValue, setContractValue] = React.useState("");
  const [alert, setAlert] = React.useState(null);

  const [beneficiaryList, setBeneficiaryList] = React.useState([{ beneficiaryAddress: "" }]);
  const [totalPrice, setTotalPrice] = React.useState(0);


  const { register, handleSubmit, watch, control, formState: { errors } } = useForm();




  const calculateTotal = (e) => {

    let dataSource = contractValue;

    if(e !== undefined) {
        dataSource = e;
    }

    try {
      if(dataSource !== null && dataSource.length > 0) {
        let weiValue = drizzle.web3.utils.toWei(dataSource, 'ether');
        let calculation = printEther(drizzle, new drizzle.web3.utils.BN(weiValue).mul(new drizzle.web3.utils.BN(beneficiaryList.length)).toString());
        setTotalPrice(calculation);
      } else {
        setTotalPrice("0 ETH");
      }
  } catch(e) {
    setTotalPrice("0 ETH");
  }
    
    
  }

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    console.log(name);
    const list = [...beneficiaryList];
    
    console.log(list);
    list[index]["beneficiaryAddress"] = value;
    setBeneficiaryList(list);
  };
 
  // handle click event of the Remove button
  const handleRemoveClick = index => {
    const list = [...beneficiaryList];
    list.splice(index, 1);
    setBeneficiaryList(list);
  };
 
  // handle click event of the Add button
  const handleAddClick = () => {
    setBeneficiaryList([...beneficiaryList, { beneficiaryAddress: "" }]);
  };

 
  const defaultValues = {
    contractValue: '0'
  };
  //const { handleSubmit, register, reset,  control } = useForm({  });


  const interactor = new AlbazeaInteractor(drizzle);

  const calculateDaysFromContractType = ()  => {
     switch(contractType.value) {
       case "AAA365":
         return 365 * 24 * 60;
      case "ZEA128":
         return 128  * 24 * 60;
      case "QUICK31":
         return 31  * 24 * 60;
      //case "AAALBERTO-X":
     //   return 1;
     }

    throw "Contract type invalid";
  }



    const hideAlert = () => {
      setAlert(null);
    };

  const handleSubmit2 = async (e) => {
    
    
    
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Confirma los datos antes de continuar"
        onConfirm={() => submitContract()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        confirmBtnText="Sí, ¡CREAR CONTRATO!" 
        cancelBtnText="Cancelar"
        showCancel
        btnSize=""
      >
        ¡Por favor comprueba que todos los datos son correctos! 
      </ReactBSAlert>
    );
  };




  

    const submitContract= async () =>  {    
      hideAlert();  
      const contractDays = calculateDaysFromContractType();
      
      try {
        await interactor.createContract(beneficiaryList.map(x=> x.beneficiaryAddress) , contractDays, allowRenewals, allowTermination, contractValue);
        return toast.success( "¡Contrato enviado a la blockchain!", { position: toast.POSITION.BOTTOM_RIGHT });
      } catch(e) {        
        return toast.error( translateException(e), { position: toast.POSITION.BOTTOM_RIGHT });
      } finally {
        
      }
  }
  

  const notify = () => toast("This is an alert message. This is an alert message.");
  
  useEffect(() => {

    calculateTotal();

      function loadWeb3Data(web3) {
        web3.eth.getAccounts()
        .then( accounts => {
            setFromAddress(accounts[0]);
            web3.eth.getBalance(accounts[0])
            .then((balance) => {
              console.log("Dinerillo obtenido: " + balance.toString());
              setFromBalance(printEther(drizzle, balance.toString()));
            });
        });
      }

      async function listenMMAccount() {
        window.ethereum.on("accountsChanged", async function (accounts) {
          console.log("accountsChanged: " + accounts);
          console.log("Account changed" +  props.accounts[0]);
          setFromAddress(props.accounts[0]);
          setFromBalance(props.accountBalances[props.accounts[0]]); 
          //setFromAddress(props.accounts[0] + eventData.toString());
          //loadWeb3Data(drizzle.web3);
        });
      }

      //listenMMAccount();
});


  return (
    <>  
      <div className="content">
        {alert}      
        <Col md={8} className="ml-auto mr-auto">
        <h1 className="text-center"><img src={logo} style={{maxWidth:"180px"}} alt="zea"></img></h1>
            <h2 className="text-center">Crea Tu Contrato De Albazea</h2>
            <p className="text-center">
              Este formulario te permitirá crear un contrato de Albazea para tus seres más queridos.
              
            </p>
        </Col>
        
        <Form className="form-horizontal" onSubmit={handleSubmit((x) => handleSubmit2(x))}>
          <Row className="mt-5">            
          
            <Col className="mr-auto ml-auto" md={12} lg={12} xl={12} >
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Crear Contrato</CardTitle>
                  </CardHeader>
                  <CardBody>
                      <Row>
                        <Col md="6">
                          <Label>Billetera de origen</Label>                      
                          <FormGroup>
                            <AccountData
                              drizzle={drizzle}
                              drizzleState={drizzleState}
                              accountIndex={0}
                              units="ether"
                              precision={3}
                              render={
                                (props) => {return (<div>{props.address}</div>)}
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col md="3">
                          <Label>Tu Balance</Label>                      
                          <FormGroup>
                          <AccountData
                              drizzle={drizzle}
                              drizzleState={drizzleState}
                              accountIndex={0}
                              units="ether"
                              precision={3}
                              render={
                                (props) => {return (<div>{props.balance} ETH</div>)}
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>                    
                        <Col md="6">
                          <Label>Tipo de contrato:</Label>               
                          <Select
                              className="react-select info"
                              classNamePrefix="react-select"
                              name="singleSelect"
                              value={contractType}
                              onChange={(value) =>  setContractType(value)}
                              options={contractTypes}
                              placeholder="Elije tu producto"
                              
                            />
                          </Col>

                        <Col md="6">
                        <Label>Dirección ETH del Beneficiario</Label>                     
                            <FormGroup>
                        
                          {beneficiaryList.map((x, i) => {
                            return (
                            <Controller  name={"beneficiaryAddress" + i.toString()} control={control} 
                                rules={{ required: true, validate: validateAddress }}
                                render={({ field }) => ( 
                                  <div>
                                  <Input {...field } 
                                      type="text" 
                                      placeholder="0x0123456789aBcDeF....." 
                                      autoComplete="off" 
                                      value={x.beneficiaryAddress}
                                      onChange={e => {
                                        handleInputChange(e,i);
                                        field.onChange(e);
                                      }}
                                      aria-invalid={errors["beneficiaryAddress" + i.toString()]?.type === 'required' || errors["beneficiaryAddress" + i.toString()]?.type === 'validate'  }
                                      invalid={errors["beneficiaryAddress" + i.toString()]?.type === 'required' || errors["beneficiaryAddress" + i.toString()]?.type === 'validate'}
                                      
                                  /> 
                                  {beneficiaryList.length !== 1 && <i className="fas fa-minus-circle"
                                    onClick={() => handleRemoveClick(i)}></i>}
                                  {beneficiaryList.length - 1 === i && i < 4 && <i className="fas fa-plus-circle" onClick={handleAddClick}></i>} 
                                </div>
                                  
                                )}
                                
                              />
                              
                            )
                            })}
                            
                            </FormGroup>
                            
                          </Col>
                      </Row>                  
                      

                      <Row>
                        <Col md="3" sm="6">
                            <Label>Renovable:</Label>                      
                              <FormGroup>
                                <div className="d-flex align-items-center">
                                  <span className="mr-2">No renovable</span>
                                  <CustomInput
                                    id="33"
                                    type="switch"
                                    className="mt-n4"
                                    name="allowRenewals"
                                    value={allowRenewals}
                                    onChange={() => {setAllowRenewals(!allowRenewals)}}
                                  />
                                  <span className="ml-n2">Renovable</span>
                                  
                                </div>
                              </FormGroup>
                        </Col>
                        <Col md="3" sm="6">
                            <Label>Cancelable:</Label>                      
                              <FormGroup>
                                <div className="d-flex align-items-center">
                                  <span className="mr-2">No</span>
                                  <CustomInput
                                    id="34"
                                    type="switch"
                                    className="mt-n4"
                                    name="allowTermination"
                                    value={allowTermination}
                                    onChange={() => {setAllowTermination(!allowTermination)}}
                                  />
                                  <span className="ml-n2">Sí</span>
                                  
                                </div>
                              </FormGroup>
                        </Col>
                        <Col md="6"  className="p-3">                     
                          <InputGroup className="input-primary">
                          <InputGroupText>Valor del contrato</InputGroupText>
                          <Controller  
                              name="contractValue" 
                              control={control} 
                              rules={{ required: true }}
                              render={({ field }) => ( 
                              <Input 
                                  {...field } 
                                  autoComplete="off"
                                  type="number"
                                  min="0.01"
                                  step="0.00000001"
                                  
                                  value={contractValue} 
                                  onChange={(e) => {
                                    setContractValue(e.target.value);
                                    field.onChange(e);    
                                    calculateTotal(e.target.value);
                                  }} 
                                  placeholder="Min 0.01 ETH (Hasta 8 decimales)"  
                                  aria-invalid={errors.contractValue?.type === 'required'}
                                  invalid={errors.contractValue?.type === 'required'}
                              />
                              )}
                          
                          />
                          </InputGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                        </Col>
                        <Col md="3">
                          <Label>Precio total en Ether</Label>                      
                          <FormGroup>
                              {totalPrice}
                          </FormGroup>
                        </Col>
                      </Row>
                  </CardBody>
                  <CardFooter>                  
                      <Row>                    
                        <Col md="12">
                          <Button 
                            className="btn-fill pull-right"
                            style={{textAlign: "right"}}
                            color="info"
                            type="submit"
                          >
                            Crear Contrato
                          </Button>
                        </Col>
                      </Row>
                    
                  </CardFooter>
                  
                </Card>
                
              </Col>
              
            </Row>
            </Form>
            <Row className="mt-5">            
          <Col className="mr-auto ml-auto" md={12} lg={12} xl={12} >
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Comisiones de contrato</CardTitle>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col md="4">
                          <Label>Creación</Label>                      
                          <FormGroup>
                            <ContractData
                              drizzle={drizzle}
                              drizzleState={drizzleState}
                              contract="Albazea"
                              method="creationCommission"
                            /> %
                            </FormGroup>
                        </Col>
                        <Col md="4">
                          <Label>Fe de vida</Label>                      
                          <FormGroup>
                            <ContractData
                              drizzle={drizzle}
                              drizzleState={drizzleState}
                              contract="Albazea"
                              method="faithOfLifeCommission"
                            /> %
                            </FormGroup>
                        </Col>
                        <Col md="4">
                          <Label>Cancelación</Label>                      
                          <FormGroup>
                            <ContractData
                              drizzle={drizzle}
                              drizzleState={drizzleState}
                              contract="Albazea"
                              method="terminationCommission"
                            /> %
                            </FormGroup>
                        </Col>
                    </Row>
                </CardBody>
              </Card>
            </Col>
        </Row>
      </div>

      
    </>
  );
};



/*

const CreateContract = (props,) => {

  
  const [singleSelect, setsingleSelect] = React.useState("AAA365");
  const handleSubmit=(e) =>  {
    e.preventDefault();
    alert('hello');
  };

  let x = null; 

  console.log(x);

  return (
 
      <DrizzleContext.Consumer>
        {drizzleContext => {
          const {drizzle, drizzleState, initialized} = drizzleContext;

          if(!initialized) {
            return "Loading..."
          }
          //drizzle.contracts.Albazea.methods.createContract('0x29d7d1dd5b6f9c864d9db560d72a247c178ae86b', 30, true, 0, 0).send({value: drizzle.web3.utils.toWei("1", 'ether')});
          return (
            <Button onClick={handleSubmit}>Cliqueame</Button>
            )
          }}
      </DrizzleContext.Consumer>
 
  );
};


*/





// const HomeContainer = drizzleConnect(Home, mapStateToProps);


// const mapStateToProps = (state) => {
//   return { name: state };


  const mapStateToProps = state => {
    return {
      accounts: state.accounts,
      accountBalances: state.accountBalances,
      drizzleStatus: state.drizzleStatus,
      Albazea: state.contracts.Albazea,
      web3: state.web3
    }
  }


const AppContainer = drizzleConnect(CreateContract, mapStateToProps);
export default AppContainer;




import { Drizzle } from "@drizzle/store";


class AlbazeaInteractor {
    constructor(drizzle) {
        this.drizzleInstance = drizzle;
        this.contract = this.drizzleInstance.contracts.Albazea;
        this.drizzleInstance.web3.eth.handleRevert = true;
        this.web3Contract = this._constructWeb3Contract();
    }

    _constructWeb3Contract() {
        const contractAddress = this.contract.address;    
        const contractAbi = this.contract.abi;
        return new this.drizzleInstance.web3.eth.Contract(contractAbi, contractAddress);    
    }

    async createContract(beneficiaryList, minutes, flexible, terminable, amountStr) {    
        console.log('hello');
        console.log(`Contrato: ${flexible}`);
        console.log(`Contrato: ${amountStr}`);
        console.log(`Contratox: ${this.drizzleInstance}`);

        let amountInWeiStr = this.drizzleInstance.web3.utils.toWei(amountStr, 'ether').toString();
        let beneficiaryListLengthStr = beneficiaryList.length.toString();
        console.log("hey");
        console.log(amountInWeiStr);
        console.log(beneficiaryList.length);
        let totalAmountStr = '';
        try {
            let bigNumber = new this.drizzleInstance.web3.utils.BN(amountInWeiStr);
            console.log(bigNumber);

            totalAmountStr = bigNumber.mul(new this.drizzleInstance.web3.utils.BN(beneficiaryListLengthStr)).toString();
        } catch(e) {
            console.log("heyyyyyyyyyyyyyyyyyy" + e);
        }
        
        console.log(totalAmountStr);
        

        try {
            await this.contract.methods.createContractBatch(beneficiaryList, minutes, flexible, terminable, 0).send({value: totalAmountStr});
        } catch(e) {
            console.log(e);
            throw e;
        }
    }

    async faithOfLife(idContract) {
        return await this.contract.methods.faithOfLife(idContract).send();
    }

    async executeContract(idContract) {
        return await this.contract.methods.executeContract(idContract).send();
    }

    async terminateContract(idContract) {
        return await this.contract.methods.terminateContract(idContract).send();
    }
    
    async getContractsByCreator(account) { 
        
        let data = await this.contract.methods.getContractsByCreator(account).call();
        return data;
    }

    async getContractsByBeneficiary( account) { 
        let data = await this.contract.methods.getContractsByBeneficiary(account).call();
        return data;
    }

    
  async  getPastEvents(options, event ='allEvents') {

    let events = await this.web3Contract.getPastEvents(event, {
      fromBlock: 0,
      toBlock: 'latest'
    });
    console.log(events);

    return events;
  }
}

function translateException(exceptionParams) {
    let reason = "Ha ocurrido un error no definido";

    if(exceptionParams.reason !== undefined) {
        reason = exceptionParams.reason;
    } else if(exceptionParams.code !== undefined) {
        if(exceptionParams.code === 4001) {
            reason = "El usuario ha cancelado la transacción";
        } else {
            reason = "Ha ocurrido un error no definido con código: " + exceptionParams.code.toString();
        }
    } 

    return reason;
}

export  {translateException, AlbazeaInteractor};

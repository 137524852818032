
  const printEther = (drizzle, wei) => {
    let weiStr;

    if(wei === undefined) {
      weiStr = '???';
    } else {
      weiStr = drizzle.web3.utils.fromWei(wei.toString()).toString();
    }

    return weiStr + " ETH";
  };

  const  addMinutes = (date, minutes) => {
    return new Date(date.getTime() + minutes*60000);
  }

  
  const isContractWithdrawable = (lastTimestamp, expiricyMinutes, status) => {
    let expiricyMinutesInt = parseInt(expiricyMinutes);
    let contractExpiricyDateTime = new Date(0);
    contractExpiricyDateTime.setUTCSeconds(lastTimestamp);
    contractExpiricyDateTime = addMinutes(contractExpiricyDateTime, expiricyMinutesInt);
    
    let currentDateTime = new Date();
    return contractExpiricyDateTime <= currentDateTime && status == 0;
  }

  const isContractTerminable = (contractStatus, isTerminable) => {
    
    return contractStatus == 0 && isTerminable;
  }

  const isContractRenewable = (contractStatus, isFlexible) => {
    
    return contractStatus == 0 && isFlexible;
  }

  
  const printTime = (minutes)  => {
      let qty = minutes;
      let unit = "Minutos";
      

      if(minutes / 60 / 24 > 1 ) {
        qty = minutes / 60 / 24;
        unit = "Días";
      } else  if(minutes / 60  > 1 ) {
        qty = minutes / 60;
        unit = "Horas";
      } 

      return `${qty} ${unit}`


  }

  export {printEther, isContractWithdrawable, isContractTerminable, isContractRenewable, printTime};


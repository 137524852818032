import { Drizzle, generateStore, EventActions } from "@drizzle/store";
import { toast } from 'react-toastify';
import * as React from 'react';
import {ReactNotificationAlert} from 'react-notification-alert';

import { AlbazeaEvents } from "albazea/global";

const processedEvents = new Set();

function processContractCreatedEvent(creator, beneficiary, amount, contractId, state) {
    
    if(creator  === state.accounts[0]) {
        let msg = `¡Se ha creado correctamente tu AlbaZea #${contractId} desde tu cuenta ${creator}!`;
        toast(msg);
    } else if( beneficiary === state.accounts[0]) {
        let msg = `La dirección ${creator} acaba de crear una AlbaZea #${contractId} para ti valorada en: ${amount} wei`;
        toast(msg);
    } 
}

function processContractTerminatedEvent(creator, beneficiary, contractId, state) {
    if(beneficiary === state.accounts[0]) {
        let msg = `La dirección ${creator} acaba de terminar la AlbaZea # ${contractId} de la que tú eras beneficiario`;
        toast(msg);
    } else if(creator  === state.accounts[0]) {
        let msg = `¡Se ha terminado tu AlbaZea #${contractId}!`;
        toast(msg);
    }
}

function processContractProcessedEvent(creator, beneficiary, contractId, state) {
    if(beneficiary === state.accounts[0]) {
        let msg = `¡Acabas de retirar tu AlbaZea #${contractId} correctamente!`;
        toast(msg);
    } else if(creator  === state.accounts[0]) {
        let msg = `¡El beneficiario de tu AlbaZea #${contractId} acaba de retirar su balance!`;
        toast(msg);
    }
}

function ContractFaithOfLifeRefreshedEvent(creator, beneficiary, contractId, state) {
    if(beneficiary === state.accounts[0]) {
        let msg = `La AlbaZea #${contractId} de la cual tú eres beneficiario ha sido extendida con una fe de vida`;
        toast(msg);
    } else if(creator  === state.accounts[0]) {
        let msg = `Se ha procesado tu fe de vida correctamente para tu AlbaZea #${contractId}`;
        toast(msg);
    }
}



function filterEvent(action, store) {
    


  

    if(action.name == 'Albazea') {
        //console.log("Event received" + JSON.stringify(action));
        let state = store.getState();

        if(action.event.event === AlbazeaEvents.ContractCreated) {
            processContractCreatedEvent(action.event.returnValues.creator, 
                action.event.returnValues.beneficiary, 
                action.event.returnValues.amount, 
                action.event.returnValues.idContract, 
                state);
        } else if(action.event.event === AlbazeaEvents.ContractProcessed) {
            processContractProcessedEvent(action.event.returnValues.creator, 
                action.event.returnValues.beneficiary, 
                action.event.returnValues.idContract, 
                state);
        } else if(action.event.event === AlbazeaEvents.ContractTerminated) {
            processContractTerminatedEvent(action.event.returnValues.creator, 
                action.event.returnValues.beneficiary, 
                action.event.returnValues.idContract, 
                state);
        } else if(action.event.event === AlbazeaEvents.ContractFaithOfLifeRefreshed) {
            ContractFaithOfLifeRefreshedEvent(action.event.returnValues.creator, 
                action.event.returnValues.beneficiary, 
                action.event.returnValues.idContract, 
                state);
        } else {
            console.error("Event not known: " + action.event.event);
        }
        
    }
}



const ContractEventNotifier = store => next => action => {

    if (action.type === EventActions.EVENT_FIRED) {
        
        console.log("evento cazado!!!");

        if (processedEvents.has(action.event.transactionHash)) {
            return;  // ignore event since we've already handled it
        } else {
            processedEvents.add(action.event.transactionHash);
        }
            // do something
    
        
        
        const contract = action.name;
        const contractEvent = action.event.event;
        const returnValues = action.event.returnValues;
        const display = `${contract}(${contractEvent})`;

        console.log("===================================");
        console.log(contract);
        console.log(contractEvent);
        console.log(returnValues);
        console.log(display);
        console.log("===================================");
        //toast(display);
        
        filterEvent(action, store);
        
    }
    return next(action);
  };


export {ContractEventNotifier};


